<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Chi tiết tài khoản</h6>

          <div class="text-right">
            <button
              v-if="parseInt(account.is_block) === 1"
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="showChange('NOT_LOCK')"
            >
              Mở khóa tài khoản
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="showChange('LOCK')"
            >
              Khóa tài khoản
            </button>

            <button
              v-if="parseInt(account.status) === 2"
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="showChange('CANCEL_AUTH')"
            >
              Hủy xác thực
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#exampleModal"
              @click="showChange('APPLY_AUTH')"
            >
              Xác thực
            </button>

            <button
              v-if="
                parseInt(walletAccount.error_code) === 0 &&
                parseInt(walletAccount.data.locked_type) === 0
              "
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#lock_wallet"
              @click="lockBalance()"
            >
              Khóa số dư ví
            </button>
            <button
              v-if="
                parseInt(walletAccount.error_code) === 0 &&
                parseInt(walletAccount.data.locked_type) === 1
              "
              type="button"
              class="btn btn-primary mr-2"
              data-toggle="modal"
              data-target="#lock_wallet"
              @click="lockBalance()"
            >
              Mở khóa số dư ví
            </button>
          </div>
        </template>
        <div class="d-flex">
          <div class="mr-3 mb-3">
            <a href="#/tools/users/account" title="List">
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 12h14M5 12l4-4m-4 4 4 4"
                />
              </svg>
            </a>
          </div>
          <div class="mr-3">
            <a
              :href="'#/tools/users/account/edit/' + $route.params.id"
              title="Edit"
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                />
              </svg>
            </a>
          </div>
          <div class="mr-3">
            <a href="#" @click="banAccount()" title="Hủy kích hoạt">
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m6 6 12 12m3-6a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </a>
          </div>
        </div>
        <div class="m-portlet">
          <div class="row">
            <div class="col-sm-6">
              <div class="m-portlet__body">
                <table class="table table-striped table-hover table-bordered">
                  <tbody>
                    <tr>
                      <td width="20%">ID</td>
                      <td>{{ response.id }}</td>
                    </tr>
                    <tr>
                      <td>Tên ví</td>
                      <td>{{ response.name }}</td>
                    </tr>
                    <tr>
                      <td>Số dư</td>
                      <td>{{ formatMoneyVn(response.balance) }}</td>
                    </tr>
                    <tr>
                      <td>Ngày tạo</td>
                      <td>{{ response.created_at }}</td>
                    </tr>
                    <tr>
                      <td>Ngày cập nhật</td>
                      <td>{{ response.updated_at }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="m-portlet__body"
                v-if="account"
                style="margin-top: 40px; margin-bottom: 40px"
              >
                <table class="table table-striped table-hover table-bordered">
                  <tbody>
                    <tr>
                      <td width="20%">ID</td>
                      <td>{{ account.id }}</td>
                    </tr>
                    <tr>
                      <td>Họ tên</td>
                      <td>{{ account.name }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>
                        <a
                          v-if="account.email"
                          :href="'mailto:' + account.email"
                          >{{ account.email }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Số điện thoại</td>
                      <td>
                        <a
                          v-if="account.phone"
                          :href="'tel:' + account.phone"
                          >{{ account.phone }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Địa chỉ</td>
                      <td>
                        {{ account.profile ? account.profile.address : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td>Số CMT/TCCCD</td>
                      <td>
                        {{ account.profile ? account.profile.id_card_no : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td>Quốc tịch</td>
                      <td>
                        {{ account.profile ? account.profile.country : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td>Giới tính</td>
                      <td>
                        {{
                          account.profile
                            ? genders.find(
                                (item) =>
                                  item.key === parseInt(account.profile.gender)
                              ).value
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Ngày sinh</td>
                      <td>
                        {{ account.profile ? account.profile.birthday : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td>Loại</td>
                      <td>
                        {{
                          parseInt(account.type) === 2
                            ? "Doanh nghiệp"
                            : "Cá nhân"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Trạng thái</td>
                      <td>
                        <span v-if="account.status">
                          {{
                            statusAccount.find(
                              (item) => item.key === parseInt(account.status)
                            ).value || account.status
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Is Verify email</td>
                      <td>
                        <span v-if="account.profile">
                          {{
                            account.profile.email_verified
                              ? "Đã xác thực"
                              : "Chưa xác thực"
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Avatar</td>
                      <td>
                        <img
                          v-if="account.profile && account.profile.avatar"
                          :src="account.profile.avatar"
                          width="120px"
                          class="img-thumbnail"
                          :alt="account.name"
                        />
                      </td>
                    </tr>

                    <tr v-if="account.risk_point">
                      <td><b>Mức độ RR</b></td>
                      <td>
                        <b v-if="account.risk_point.risk_level === 'LOW'"
                          >Thấp</b
                        >
                        <b
                          v-else-if="account.risk_point.risk_level === 'MEDIUM'"
                          >Trung bình</b
                        >
                        <b v-else>Cao</b>
                      </td>
                    </tr>
                    <tr v-if="account.risk_point">
                      <td><b>Điểm đánh giá (Thredhold)</b></td>
                      <td>
                        <b>{{ account.risk_point.point }}</b>
                      </td>
                    </tr>
                    <tr v-if="account.risk_point">
                      <td><b>Hạn mức ảnh hưởng</b></td>
                      <td>
                        <b>{{
                          parseInt(account.risk_point.impact_level) === 1
                            ? "Giữ nguyên hạn mức"
                            : "Giảm 30% hạn mức"
                        }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="m-portlet__body clearfix">
                <h4>Danh sách ngân hàng liên kết</h4>
                <div
                  class="table-responsive"
                  v-if="banks.error_code === 0 && banks.data.length > 0"
                >
                  <table
                    class="table m-table m-table--head-separator-danger table-bordered table-hover table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Tên ngân hàng</th>
                        <th>Chủ tài khoản</th>
                        <th>Số tài khoản tài khoản</th>
                        <th>Loại LK</th>
                        <th>Ngày liên kết</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(bank, index) in banks.data" :key="index">
                        <td>{{ bank.bank_name }}</td>
                        <td>{{ bank.bank_holder_name }}</td>
                        <td>
                          {{
                            bank.bank_account ||
                            bank.bank_id_card_no ||
                            bank.token
                          }}
                        </td>
                        <td>{{ bank.type !== "PAYGATE" ? "Ví" : "Cổng" }}</td>
                        <td>{{ formatDate(bank.created_at) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-responsive" v-else>
                  <p class="text-center text-danger">Không có dữ liệu</p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="m-portlet__body">
                <div class="mb-5">
                  <h4>Ảnh mặt trước CTM/Thẻ CCCD/Hộ chiếu</h4>
                  <div class="img_profile">
                    <img
                      v-if="account.profile.id_card_front_image"
                      :src="account.profile.id_card_front_image"
                      width="100%"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <h4>Ảnh mặt sau CTM/Thẻ CCCD/Hộ chiếu</h4>
                  <div class="img_profile">
                    <img
                      v-if="account.profile.id_card_back_image"
                      :src="account.profile.id_card_back_image"
                      width="100%"
                      class="img-thumbnail"
                      alt=""
                    />
                  </div>
                </div>

                <div class="mb-5">
                  <h4>Ảnh chân dung</h4>
                  <div class="img_profile">
                    <img
                      v-if="account.profile.image"
                      :src="account.profile.image"
                      width="100%"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <h4>Ảnh trong NFC</h4>
                  <div class="img_profile">
                    <img
                      v-if="account.profile.nfc_image"
                      :src="account.profile.nfc_image"
                      width="100%"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="m-portlet__body" style="margin-top: 40px">
            <table class="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">Loại hành động</th>
                  <th scope="col">Lý do</th>
                  <th scope="col">Ngày thao tác</th>
                  <th scope="col">Người thao tác</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(log, index) in account.data_log" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    <span>{{ getActionType(log.type) }}</span>
                  </td>
                  <td>{{ log.description }}</td>
                  <td>{{ log.created_at }}</td>
                  <td>{{ log.created_by }}</td>
                </tr>
                <tr v-for="(log, index) in reason" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    <span>{{ getActionType(log.type) }}</span>
                  </td>
                  <td>
                    <span>{{
                      getReasonDescription(log.type, log.reason)
                    }}</span>
                  </td>
                  <td>{{ log.created_at }}</td>
                  <td>{{ log.created_by }}</td>
                </tr>
                <tr v-for="(log, index) in reasonLock" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    <span>{{ getActionType(log.entity_type) }}</span>
                  </td>
                  <td>
                    <span>{{
                      getReasonDescription(log.entity_type, log.reason)
                    }}</span>
                  </td>
                  <td>{{ log.created_at }}</td>
                  <td>{{ log.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card>
    </b-card-group>
    <b-modal
      id="lock-account"
      hide-footer
      ref="myModal"
      :title="titleModalLockAccount"
    >
      <b-form class="col-12">
        <b-form-group label="Lý do">
          <b-form-textarea v-model="reasonLockAccount"></b-form-textarea>
        </b-form-group>
        <b-row style="float: right">
          <b-button
            class="mr-2"
            type="submit"
            variant="primary"
            v-on:click="
              $bvModal.hide('lock-account');
              reasonLockAccount = '';
            "
            >Hủy
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            v-on:click="onChangeStatusUser()"
            >Lưu
          </b-button>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      v-if="walletAccount.data && parseInt(walletAccount.error_code) !== 1"
      id="lock-balance"
      hide-footer
      title="Cập nhật trạng thái cộng trừ ví"
    >
      <b-form class="col-12">
        <span>
          Trạng thái cộng trừ ví hiện tại:
          <strong
            class="font-weight-bold"
            v-if="parseInt(walletAccount.data.locked_type) === 0"
            >Mở khoá số dư</strong
          >
          <strong
            class="font-weight-bold"
            v-else-if="parseInt(walletAccount.data.locked_type) === 1"
            >Khoá số dư</strong
          >
          <strong class="font-weight-bold" v-else>Không xác định</strong>
        </span>
        <b-form-select label="Loại khóa" v-model="typeLockBalance">
          <option
            value="1"
            selected
            v-if="parseInt(walletAccount.data.locked_type) === 0"
          >
            Khoá số dư
          </option>
          <option
            value="0"
            selected
            v-if="parseInt(walletAccount.data.locked_type) === 1"
          >
            Mở khoá số dư
          </option>
        </b-form-select>
        <b-form-group label="Lý do">
          <b-form-textarea v-model="reasonLockBalance"></b-form-textarea>
        </b-form-group>
        <b-row style="float: right">
          <b-button
            class="mr-2"
            type="submit"
            variant="primary"
            v-on:click="
              $bvModal.hide('lock-balance');
              reasonLockBalance = '';
            "
            >Hủy
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            v-on:click="onChangeLockBalance()"
            >Lưu
          </b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  mixins: [Common],
  data() {
    return {
      account: {},
      walletAccount: {},
      response: {},
      reason: [],
      reasonLock: [],
      banks: {},
      genders: [
        { key: 0, value: "N/A" },
        { key: 1, value: "Nam" },
        { key: 2, value: "Nữ" },
      ],
      statusAccount: [
        { key: null, value: "Chọn trạng thái" },
        { key: -3, value: "Xác thực thất bại" },
        { key: -2, value: "Đang chờ" },
        { key: 1, value: "Kích hoạt" },
        { key: 2, value: "Đã xác thực" },
        { key: -1, value: "Đã xác thực 1 bước" },
      ],
      titleModalLockAccount: "",
      reasonLockAccount: "",
      reasonLockBalance: "",
      typeChange: null,
      typeLockBalance: 0,
    };
  },
  methods: {
    onChangeLockBalance() {
      CmsRepository.lockBalanceAccount({
        type: this.typeLockBalance,
        phone: this.account.phone,
        reason: this.reasonLockBalance,
      })
        .then((response) => {
          this.$bvModal.hide("lock-balance");
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.notifyAlert(
            "success",
            "Thay đổi trạng thái tài dư thái khoản thành công"
          );
          this.getDetail();

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    lockBalance() {
      this.$bvModal.show("lock-balance");
    },
    showChange(type) {
      this.typeChange = type;
      if (type === "NOT_LOCK") {
        this.titleModalLockAccount = "Mở khóa tài khoản";
      } else if (type === "LOCK") {
        this.titleModalLockAccount = "Khóa tài khoản";
      } else if (type === "CANCEL_AUTH") {
        this.titleModalLockAccount = "Hủy xác thực";
      } else if (type === "APPLY_AUTH") {
        this.titleModalLockAccount = "Xác thực";
      } else {
        alert("Có lỗi xảy ra! Không tìm thấy type form");
        return;
      }
      this.reasonLockAccount = "";
      this.$bvModal.show("lock-account");
    },
    onChangeStatusUser() {
      this.$bus.$emit("show-loading", true);
      CmsRepository.changeStatusUser(this.$route.params.id, {
        type: this.typeChange,
        description: this.reasonLockAccount,
      })
        .then((response) => {
          this.$bvModal.hide("lock-account");
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          if (response.data.success) {
            this.notifyAlert(
              "success",
              "Thay đổi trạng thái tài khoản thành công"
            );
            this.getDetail();
          } else {
            this.notifyAlert(
              "warn",
              "Có lỗi khi thay đổi trạng thái tài khoản"
            );
          }

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    banAccount() {
      if (!confirm("Bạn có chắc là Không kích hoạt tài khoản này không?")) {
        return;
      }

      this.$bus.$emit("show-loading", true);
      CmsRepository.banAccount(this.account.id)
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }
          this.notifyAlert("success", " Cập nhật trạng thái thành công ");

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    getActionType(type) {
      switch (type) {
        case "LOCK":
          return "Khóa tài khoản";
        case "NOT_LOCK":
          return "Mở khóa tài khoản";
        case "CANCEL_AUTH":
          return "Hủy xác thực tài khoản";
        case "APPLY_AUTH":
          return "Xác thực tài khoản";
        case "ACCOUNT_WALLET_LOCK":
          return "Khóa số dư ví";
        case "ACCOUNT_WALLET_UNLOCK":
          return "Mở khóa số dư ví";
        default:
          return type;
      }
    },
    getReasonDescription(type, reason) {
      switch (type) {
        case "ACCOUNT_WALLET_LOCK":
          return `Hệ thống tự động khóa theo ${reason}`;
        case "ACCOUNT_WALLET_UNLOCK":
          return `Hệ thống tự động mở khóa theo ${reason}`;
        default:
          return "";
      }
    },
    getDetail() {
      this.$bus.$emit("show-loading", true);
      CmsRepository.viewAccount(this.$route.params.id)
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.account = response.data.data.account;
          this.walletAccount = response.data.data.walletAccount;
          this.response = response.data.data.response;
          this.reason = response.data.data.reason;
          this.reasonLock = response.data.data.reasonLock;
          this.banks = response.data.banks;

          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.getDetail();
  },
};
</script>
